import { ComponentType, FC, Suspense, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import Loader from 'components/Loader';
import Layout from 'components/Layout';
import CheckMaintenance from 'components/Maintenance/CheckMaintenance';
import { UserResponse } from 'types';

interface AuthenticationRouteProps {
  auth?: {
    user: UserResponse | null;
    loading: boolean;
  };
  component: ComponentType<any>;
  path: string;
  isAuth?: boolean | null;
  allowInMaintenance?: boolean;
  noLayout?: boolean;
  exact?: boolean;
  overflowScroll?: boolean;
}

const AuthenticationRoute: FC<AuthenticationRouteProps> = ({
  auth,
  isAuth = null,
  allowInMaintenance = false,
  noLayout = false,
  component: RouteComponent,
  path,
  exact,
  overflowScroll,
}) => {
  const { user, loading } = auth ?? {};

  useEffect(() => {
    if (user && window.clarity) {
      window.clarity('identify', user.email);
    }
  }, [user]);

  return (
    <Route
      path={path}
      exact={exact}
      render={(props) => {
        if (loading && !user && (isAuth !== null || !noLayout)) {
          return <Loader />;
        }

        if (user && isAuth === false) {
          const redirect = props.location.state as any;

          return <Redirect to={redirect?.from ?? '/'} />;
        }

        if (!user && isAuth === true) {
          return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
        }

        if (!user || noLayout) {
          return (
            <CheckMaintenance user={user} allowInMaintenance={allowInMaintenance}>
              {/* @ts-ignore */}
              <RouteComponent />
            </CheckMaintenance>
          );
        }

        return (
          <CheckMaintenance user={user} allowInMaintenance={allowInMaintenance}>
            <Layout overflowScroll={overflowScroll}>
              <Suspense fallback={<Loader />}>
                {/* @ts-ignore */}
                <RouteComponent />
              </Suspense>
            </Layout>
          </CheckMaintenance>
        );
      }}
    />
  );
};

export default AuthenticationRoute;
